import React, { useEffect } from "react"
import Page from "../templates/Page"

export default ({ location }) => {
  useEffect(() => {
    const script = document.createElement("script")
    script.async = true
    script.defer = true
    script.src =
      "https://plannc.matomo.cloud/index.php?module=CoreAdminHome&action=optOutJS&divId=matomo-opt-out&language=auto&showIntro=1"
    document.querySelector("div#matomo-opt-out").appendChild(script)
  }, [])
  return (
    <Page
      title="Politique de confidentialité"
      location={location}
      forceMap={false}
    >
      <h1>Politique de confidentialité</h1>
      <p>&nbsp;</p>
      <h2>I. Introduction</h2>
      <p>
        La présente politique de confidentialité définit et vous informe de la
        manière dont Mediareseau SARL utilise et protège les informations que vous
        nous transmettez, le cas échéant, lorsque vous utilisez le présent site
        accessible à partir de l&rsquo;URL suivante : plan.nc (ci-après le
        &laquo; Site &raquo;).
        <br />
        Veuillez noter que cette politique de confidentialité est susceptible
        d&rsquo;être modifiée ou complétée à tout moment par Mediareseau SARL,
        notamment en vue de se conformer à toute évolution législative,
        réglementaire, jurisprudentielle ou technologique. Dans un tel cas, la
        date de sa mise à jour sera clairement identifiée en tête de la présente
        politique. Ces modifications engagent l&rsquo;Utilisateur dès leur mise
        en ligne. Il convient par conséquent que l&rsquo;Utilisateur consulte
        régulièrement la présente politique de confidentialité et
        d&rsquo;utilisation des cookies afin de prendre connaissance de ses
        éventuelles modifications.
      </p>
      <h2>II. Données personnelles</h2>
      <p>
        D&rsquo;une manière générale, il vous est possible de visiter le Site de
        Mediareseau SARL sans communiquer aucune information personnelle vous
        concernant. En toute hypothèse, vous êtes en aucune manière obligé de
        transmettre ces informations à Mediareseau SARL.
        <br />
        Néanmoins, en cas de refus, il se peut que vous ne puissiez pas
        bénéficier de certaines informations ou services que vous avez demandé.
        A ce titre en effet, Mediareseau SARL peut être amené dans certains cas à vous
        demander de renseigner :
      </p>
      <ul>
        <li>Nom d&#39;utilisateur</li>
        <li>Mot de passe</li>
        <li>Adresse mail</li>
      </ul>
      <p>
        (ci-après vos &laquo; Informations Personnelles &raquo;). En fournissant
        ces informations, vous acceptez expressément qu&rsquo;elles soient
        traitées par Mediareseau SARL, aux fins indiquées au point 2 ci-dessous ainsi
        qu&rsquo;aux fins rappelées à la fin de chaque formulaire.
        <br />
        Conformément au Règlement Général sur la Protection des Données (RGPD)
        adopté par le Parlement européen le 14 avril 2016, et à la Loi
        Informatique et Libertés du 6 janvier 1978 modifiée, Mediareseau SARL vous
        informe des points suivants :
      </p>
      <h3>1. Identité du responsable du traitement</h3>
      <p>Le responsable du traitement est la société :</p>
      <ul>
        <li>
          Mediareseau SARL, 1 Rue Merano - Vallée des Colons 98800 Nouméa BP : 18646
        </li>
        <li>Tél : +687 27 12 84</li>
        <li>Mail : contact@plan.nc</li>
      </ul>
      <h3>2. Finalités du traitement</h3>
      <p>
        Mediareseau SARL est susceptible de traiter vos Informations Personnelles :
      </p>
      <ol>
        <li>
          aux fins de vous fournir les informations ou les services que vous
          avez demandé (notamment : Gestion des messages du Formulaire de
          contact et Compte utilisateur).
        </li>
        <li>
          aux fins de recueillir des informations nous permettant
          d&rsquo;améliorer notre Site, nos produits et services (notamment par
          le biais de cookies).
        </li>
      </ol>
      <p>&nbsp;</p>
      <h4>a. Collecte des Informations Personnelles</h4>
      <p>Nous collectons les renseignements suivants :</p>
      <ul>
        <li>Nom d&#39;utilisateur</li>
        <li>Mot de passe</li>
        <li>Adresse mail</li>
      </ul>
      <p>
        Les renseignements personnels que nous collectons sont recueillis au
        travers de formulaires et grâce à l&#39;interactivité établie entre vous
        et notre site Web.
      </p>
      <h4>b. Formulaires et interactivité</h4>
      <p>
        Vos renseignements personnels sont collectés par le biais de formulaire,
        à savoir :
      </p>
      <ul>
        <li>Formulaire de contact</li>
        <li>Compte utilisateur</li>
      </ul>
      <p>
        Vos renseignements sont également collectés par le biais de
        l&#39;interactivité pouvant s&#39;établir entre vous et notre site Web
        et ce dans le but de :
      </p>
      <ul>
        <li>Statistiques</li>
      </ul>
      <h3>3. Destinataires</h3>
      <p>
        Seul Mediareseau SARL est destinataire de vos Informations Personnelles.
        Celles-ci, que ce soit sous forme individuelle ou agrégée, ne sont
        jamais transmises à un tiers, nonobstant les sous-traitants auxquels
        Mediareseau SARL fait appel (vous trouverez de plus amples informations à leur
        sujet au point 7 ci-dessous). Ni Mediareseau SARL, ni aucun de ses
        sous-traitants, ne procèdent à la commercialisation des données
        personnelles des visiteurs et Utilisateurs de son Site.
      </p>
      <h3>4. Durée de conservation</h3>
      <p>
        Vos Informations Personnelles sont conservées par Mediareseau SARL uniquement
        pour le temps correspondant à la finalité de la collecte tel
        qu&rsquo;indiqué en 2 ci-dessus qui ne saurait en tout état de cause
        excéder 24 mois.
      </p>
      <h3>5. Droits Informatique et Libertés</h3>
      <p>
        Vos droits en matière d&rsquo;accès à vos données personnelles.
        <br />
        Vous avez le droit d&rsquo;accéder à vos données personnelles et de
        demander qu&rsquo;elles soient rectifiées, complétées ou mises à jour.
        Vous pouvez également demander l&rsquo;effacement de vos données ou vous
        opposer à leur traitement, à condition de justifier d&rsquo;un motif
        légitime.
        <br />
        Vous pouvez demander à exercer votre droit à la portabilité de vos
        données, c&rsquo;est-à-dire le droit de recevoir les données
        personnelles que vous nous avez fournies dans un format structuré,
        couramment utilisé et le droit de transmettre ces données à un autre
        responsable de traitements
        <br />
        Vous pouvez enfin formuler des directives relatives à la conservation, à
        l&rsquo;effacement et à la communication de vos données à caractère
        personnel après votre décès.
        <br />
        Vous pouvez exercer vos droits auprès du responsable des données
        personnelles de Mediareseau SARL :
      </p>
      <ul>
        <li>
          Par courrier
          <ul>
            <li>
              Mediareseau SARL : 1 Rue Merano - Vallée des Colons 98800 Nouméa BP :
              18646
            </li>
          </ul>
        </li>
        <li>
          Par courriel
          <ul>
            <li>contact@plan.nc</li>
          </ul>
        </li>
      </ul>
      <p>
        Avant de répondre à votre demande, nous sommes susceptibles de vérifier
        votre identité et/ou vous demander de nous fournir davantage
        d&rsquo;informations pour répondre à votre demande. Nous nous
        efforcerons de donner suite à votre demande dans un délai raisonnable
        et, en tout état de cause, dans les délais fixés par la loi.
        <br />
        En cas de réponse insatisfaisante, vous pouvez introduire une
        réclamation auprès de la Commission nationale de l&rsquo;informatique et
        des libertés (CNIL) : https://www.cnil.fr/fr/plaintes.
      </p>
      <h4>a. Droit d&rsquo;accès et de communication des données</h4>
      <p>
        Vous avez la faculté d&rsquo;accéder aux Informations Personnelles qui
        vous concernent.
        <br />
        Cependant, en raison de l&rsquo;obligation de sécurité et de
        confidentialité dans le traitement des données à caractère personnel qui
        incombe à Mediareseau SARL, vous êtes informé que votre demande sera traitée
        sous réserve que vous rapportiez la preuve de votre identité, notamment
        par la production d&rsquo;un scan de votre titre d&rsquo;identité valide
        (en cas de demande par notre formulaire électronique dédié) ou
        d&rsquo;une photocopie signée de votre titre d&rsquo;identité valide (en
        cas de demande adressée par écrit).
        <br />
        Mediareseau SARL vous informe qu&rsquo;il sera en droit, le cas échéant, de
        s&rsquo;opposer aux demandes manifestement abusives (de par leur nombre,
        leur caractère répétitif ou systématique).
        <br />
        Pour vous aider dans votre démarche, notamment si vous désirez exercer
        votre droit d&rsquo;accès par le biais d&rsquo;une demande écrite à
        l&rsquo;adresse postale mentionnée au point 1, vous trouverez en
        cliquant sur le lien suivant un modèle de courrier élaboré par la
        Commission Nationale de l&rsquo;Informatique et des Libertés (la &laquo;
        CNIL &raquo;).
        https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces
      </p>
      <h4>b. Droit de rectification des données</h4>
      <p>
        Au titre de ce droit, la législation vous habilite à demander la
        rectification, la mise à jour, le verrouillage ou encore
        l&rsquo;effacement des données vous concernant qui peuvent
        s&rsquo;avérer le cas échéant inexactes, erronées, incomplètes ou
        obsolètes.
        <br />
        Egalement, vous pouvez définir des directives générales et particulières
        relatives au sort des données à caractère personnel après votre décès.
        Le cas échéant, les héritiers d&rsquo;une personne décédée peuvent
        exiger de prendre en considération le décès de leur proche et/ou de
        procéder aux mises à jour nécessaires.
        <br />
        Pour vous aider dans votre démarche, notamment si vous désirez exercer,
        pour votre propre compte ou pour le compte de l&rsquo;un de vos proches
        décédé, votre droit de rectification par le biais d&rsquo;une demande
        écrite à l&rsquo;adresse postale mentionnée au point 1, vous trouverez
        en cliquant sur le lien suivant un modèle de courrier élaboré par la
        CNIL.
        https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees
      </p>
      <h4>c. Droit d&rsquo;opposition</h4>
      <p>
        L&rsquo;exercice de ce droit n&rsquo;est possible que dans l&rsquo;une
        des deux situations suivantes :
      </p>
      <ol>
        <li>
          Lorsque l&rsquo;exercice de ce droit est fondé sur des motifs
          légitimes ; ou
        </li>
        <li>
          Lorsque l&rsquo;exercice de ce droit vise à faire obstacle à ce que
          les données recueillies soient utilisées à des fins de prospection
          commerciale.
        </li>
      </ol>
      <p>
        Pour vous aider dans votre démarche, notamment si vous désirez exercer
        votre droit d&rsquo;opposition par le biais d&rsquo;une demande écrite
        adressée à l&rsquo;adresse postale indiquée au point 1, vous trouverez
        en cliquant sur le lien suivant un modèle de courrier élaboré par la
        CNIL.
        https://www.cnil.fr/fr/modele/courrier/supprimer-des-informations-vous-concernant-dun-site-internet
      </p>
      <p>&nbsp;</p>
      <h3>6. Délais de réponse</h3>
      <p>
        Mediareseau SARL s&rsquo;engage à répondre à votre demande d&rsquo;accès, de
        rectification ou d&rsquo;opposition ou toute autre demande
        complémentaire d&rsquo;informations dans un délai raisonnable qui ne
        saurait dépasser 1 mois à compter de la réception de votre demande.
      </p>
      <h3>
        7. Prestataires habilités et transfert vers un pays tiers de
        l&rsquo;Union Européenne
      </h3>
      <p>
        Mediareseau SARL vous informe qu&rsquo;il a recours à ses prestataires
        habilités pour faciliter le recueil et le traitement des données que
        vous nous avez communiqué. Ces prestataires peuvent être situés en
        dehors de l&rsquo;Union Européenne et ont communication des données
        recueillies par le biais du ou des formulaires présents sur le Site.
        <br />
        Mediareseau SARL s&rsquo;est préalablement assuré de la mise en œuvre par ses
        prestataires de garanties adéquates et du respect de conditions strictes
        en matière de confidentialité, d&rsquo;usage et de protection des
        données. Tout particulièrement, la vigilance s&rsquo;est portée sur
        l&rsquo;existence d&rsquo;un fondement légal pour effectuer un
        quelconque transfert de données vers un pays tiers. A ce titre,
        l&rsquo;un de nos prestataires, Google Analytics est conforme avec les
        cadres légaux en lien avec le transfert de données tels que que les
        EU-US et Swiss-US Privacy Shield Framework.
        <br />
        De plus, l&rsquo;hébergement de plan.nc est pris en charge par AWS dont
        les serveurs sont présents en Australie.
      </p>
      <h3>8. Plainte auprès de l&rsquo;autorité compétente</h3>
      <p>
        Si vous considérez que Mediareseau SARL ne respecte pas ses obligations au
        regard de vos Informations Personnelles, vous pouvez adresser une
        plainte ou une demande auprès de l&rsquo;autorité compétente. En France,
        l&rsquo;autorité compétente est la CNIL à laquelle vous pouvez adresser
        une demande par voie électronique en cliquant sur le lien suivant :
        https://www.cnil.fr/fr/plaintes/internet.
      </p>
      <h4>a. Sécurité</h4>
      <p>
        Les renseignements personnels que nous collectons sont conservés dans un
        environnement sécurisé. Les personnes travaillant pour nous sont tenues
        de respecter la confidentialité de vos informations.
        <br />
        Pour assurer la sécurité de vos renseignements personnels, nous avons
        recours aux mesures suivantes :
      </p>
      <ul>
        <li>Protocole SSL (Secure Sockets Layer)</li>
        <li>Back office avec Identifiant / mot de passe requis</li>
        <li>Pare-feu (Firewalls)</li>
      </ul>
      <p>
        Nous nous engageons à maintenir un haut degré de confidentialité en
        intégrant les dernières innovations technologiques permettant
        d&#39;assurer la confidentialité de vos transactions.
        <br />
        Toutefois, comme aucun mécanisme n&#39;offre une sécurité maximale, une
        part de risque est toujours présente lorsque l&#39;on utilise Internet
        pour transmettre des renseignements personnels.
      </p>
      <h4>b. Législation</h4>
      <p>
        Nous nous engageons à respecter les dispositions législatives énoncées
        dans la GDPR de l&rsquo;Union Européenne.
      </p>
      <h2>III. Politique relative aux cookies</h2>
      <h3>1.Données statistiques</h3>
      <p>
        Afin de mieux connaître les centres d&#39;intérêt des visiteurs du site
        et en vue de son amélioration, nous pouvons être amenés à mesurer le
        nombre de pages vues, le nombre de visites, ainsi que l&#39;activité des
        visiteurs sur le présent site, et leur fréquence de retour. A cet effet,
        la technologie des &quot;cookies&quot; peut être utilisée sur des
        parties de ce site.
        <br />
        Par ailleurs, la société Mediareseau SARL peut procéder également à
        l&#39;analyse de la fréquentation du présent site Internet à partir de
        l&#39;exploitation des données de connexion.
        <br />
        Pour vous opposer à l&#39;enregistrement de cookies ou être prévenu
        avant d&#39;accepter les cookies, nous vous recommandons la lecture de
        la rubrique d&#39;aide de votre navigateur qui vous précisera la marche
        à suivre.
      </p>
      <div id="matomo-opt-out"></div>
      <h3>2. Qu&#39;est ce qu&#39;un Cookie et à quoi sert-il ?</h3>
      <p>
        Un cookie (ou témoin de connexion) est un fichier texte susceptible
        d&#39;être enregistré, sous réserve de vos choix, dans un espace dédié
        du disque dur de votre terminal (ordinateur, tablette ...) à
        l&#39;occasion de la consultation d&#39;un service en ligne grâce à
        votre logiciel de navigation.
        <br />
        Il est transmis par le serveur d&#39;un site internet à votre
        navigateur. A chaque cookie est attribué un identifiant anonyme. Le
        fichier cookie permet à son émetteur d&#39;identifier le terminal dans
        lequel il est enregistré pendant la durée de validité ou
        d&#39;enregistrement du cookie concerné. Un cookie ne permet pas de
        remonter à une personne physique.
        <br />
        Lorsque vous consultez le site de Mediareseau SARL, nous pouvons être amenés à
        installer, sous réserve de votre choix, différents cookies et notamment
        des cookies publicitaires.
      </p>
      <h3>
        3. Liens vers des sites web externe à Mediareseau SARL et des applications
        tierces
      </h3>
      <p>
        Pour vous permettre d&#39;interagir avec d&#39;autres sites Web sur
        lesquels vous possédez peut-être des comptes (tels que Facebook et
        d&#39;autres sites de médias sociaux) ou de vous joindre à des
        communautés sur ces sites, nous pouvons vous fournir des liens ou
        intégrer des applications tierces vous permettant de vous connecter, de
        publier du contenu ou de vous joindre à des communautés à partir de nos
        sites Web.
        <br />
        Nous pouvons également vous fournir des liens vers des sites Web externe
        à Mediareseau SARL.
        <br />
        L&#39;utilisation de ces liens et applications est soumise aux
        politiques de confidentialité des sites tiers avec lesquelles vous devez
        vous familiariser avant d&#39;utiliser les liens ou les applications.
        <br />
        Mediareseau SARL ne saurait être tenue responsable de leurs pratiques en
        matière de confidentialité ou de leur contenu.
      </p>
      <h3>4. Liste des cookies utilisés par Mediareseau SARL</h3>
      <p>Voici la liste que nous utilisons majoritairement sur notre site :</p>
      <ul>
        <li>Google Analytics</li>
      </ul>
      <h3>5. Combien de temps les cookies sont-ils actifs ?</h3>
      <p>
        Les cookies arrivent en général à échéance au plus tard 13 mois après
        avoir été placé sur l&rsquo;ordinateur ou le terminal mobile de
        l&rsquo;utilisateur.
      </p>
      <h3>6. Paramètres des cookies</h3>
      <p>
        Si vous refusez que des cookies soient installés sur votre ordinateur ou
        votre terminal mobile, vous pouvez configurer votre navigateur pour
        limiter ou exclure l&rsquo;utilisation de cookies. Votre navigateur peut
        vous fournir des informations relatives au paramétrage des cookies. Vous
        pouvez notamment configurer votre navigateur afin de vous alerter dès
        qu&rsquo;un site internet visité utilise des cookies. Vous pouvez
        également configurer votre navigateur pour refuser tous les cookies ou
        accepter certains types de cookies. D&rsquo;une façon générale, vous
        pouvez désactiver la fonctionnalité de cookie sur votre navigateur, sans
        affecter votre l&rsquo;ergonomie de votre visite sur le site de
        Mediareseau SARL.
        <br />
        Notez bien que ces paramètres ne sont valables que pour les pages créées
        et détenues par Mediareseau SARL. Lorsque nous intégrons des liens vers des
        sites internet externes et que vous cliquez sur ces liens, vous quittez
        notre site : dès lors, les paramètres et les politiques applicables à
        notre site Web ne s&#39;appliquent plus. Il vous appartient alors de
        consulter sur ledit site, la politique relative à la protection des
        données et aux cookies.
        <br />
        Si vous ne souhaitez pas recevoir de cookies de la part de notre site
        Internet, vous pouvez configurer votre navigateur afin de les refuser ou
        qu&#39;il vous avertisse lorsque vous en recevez un, vous demandant
        alors si vous acceptez ou non le placement d&rsquo;un tel cookie. Vous
        pouvez également paramétrer votre navigateur afin de désactiver les
        cookies. Pour comprendre comment faire, veuillez consulter
        l&rsquo;onglet &laquo; Outils &raquo; de votre navigateur, ou toute
        autre dénomination similaire.
        <br />
        Nous vous recommandons toutefois de ne pas désactiver nos cookies.
        Gardez à l&#39;esprit que si vous bloquez, éteignez, ou rejetez nos
        cookies, certaines pages de notre site Internet ne pourront plus
        s&#39;afficher correctement ou vous ne serez plus en mesure
        d&#39;utiliser certains services vous demandant de vous connecter.
        <br />
        Si vous refusez d&rsquo;avoir nos cookies placés sur votre ordinateur,
        vous devrez dès lors les désactiver. Pour ce faire, veuillez suivre les
        instructions appropriées ci-dessous en fonction de votre navigateur :
      </p>
      <ul>
        <li>
          Si vous utilisez le navigateur
          <a href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies">
            {" "}
            Internet Explorer.
          </a>
        </li>
        <li>
          Si vous utilisez le navigateur
          <a href="https://support.mozilla.org/fr/kb/autoriser-bloquer-cookies-preferences-sites?redirectlocale=fr&amp;redirectslug=activer-desactiver-cookies">
            {" "}
            Firefox
          </a>
          .
        </li>
        <li>
          Si vous utilisez le navigateur
          <a href="https://support.apple.com/kb/ph5042?locale=fr_FR"> Safari</a>
          .
        </li>
        <li>
          Si vous utilisez le navigateur
          <a href="https://support.google.com/chrome/answer/95647?hl=fr">
            {" "}
            Google Chrome.
          </a>
        </li>
      </ul>
      <p>
        <em>Mise en ligne date de mise en application : </em>Mardi 27 juin 2019
      </p>
    </Page>
  )
}
